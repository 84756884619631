.Error {
    border: 2px solid var(--negative-color);
    background-color: var(--negative-muted-color);
    color: white;
    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-medium);
    padding: var(--padding-medium);
    border-radius: var(--border-radius);
    text-align: center;
}
