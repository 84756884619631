.Approved{
    color: var(--positive-color);
}

.NotApproved {
    color: var(--negative-color);
}

.Unknown {
    color: var(--secondary-color);
}


.Mark {
    display: inline-block;
    height: 100%;
    font-weight: bold;
}

.Small {

}

.Large {
    font-size: 80px;
}
