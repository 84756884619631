.Container {
    -webkit-animation: fadeIn linear 1s;
    animation: fadeIn linear 1s;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@-webkit-keyframes fadeIn {
    0% { opacity: .1; }
    100% { opacity: 1; }
}
@keyframes flash {
    0% { opacity: .1; }
    100% { opacity: 1; }
}
