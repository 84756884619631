.Logo img {
  font-size: 4em;
  margin-bottom: 0em;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: auto;
}

.Logo {
  text-align: center;
  margin-bottom: var(--margin-medium);
}
