.Container {
    width: 100%;
    border-style: solid;
    border-width: var(--border-width);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    border-radius: var(--border-radius);
}

.Header {
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    padding: var(--padding-small);
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.Body {
    padding: var(--padding-tiny);
}

