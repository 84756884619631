.App {
  margin: 0 auto;
  padding: var(--padding-medium);

  color: var(--default-text-color);
}

.FullWidth {
}

.Centered {
  max-width: 800px;
}

.AppHeader {
  padding-top: 1em;
  display: flex;
  justify-content: center;
}

.AppHeaderIcon {
  display: flex;
  align-items: center;
}

.AppBody {
  min-height: 20vh;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
  justify-content: center;
  /*padding-bottom: var(--padding-large);*/
}

.AppBody h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--default-header-text-color);
}

.AppBody h1 {
  font-size: 4em;
}

.AppFooter {
  margin-bottom: var(--margin-medium);
  text-align: center;
}

.AppFooter .AppDisclaimer {
  margin-bottom: var(--margin-medium);
}

.AppDisclaimer {
  font-size: 12px;
  font-style: italic;
}

.FooterLinks {
}
