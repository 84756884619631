.Header {
  margin-bottom: var(--margin-small);
}

.List {
  width: 100%;

  list-style-type: none;
  padding: 0;
}

.ListItem {
  background-color: var(--background-color);
  border-color: var(--background-color);
  border-style: solid;
  border-width: 1px;
  color: var(--primary-color);
  padding-left: var(--padding-small);
  padding-right: var(--padding-small);

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  margin-bottom: var(--margin-small);
  cursor: pointer;
}

.ListItem a {
  text-decoration: none;
}

.ListItemLink {
  padding: var(--padding-small);
  flex-grow: 1;
}

.IconLeft {
  margin-right: var(--margin-small);
}

.ApprovedListItem {
  background-color: var(--background-color);
}

.NotApprovedListItem {
  background-color: white;
}
