.LabelLink {
    display: inline-block;
    margin-left:  var(--margin-small);
    margin-right:  var(--margin-small);
    margin-bottom:  var(--margin-small);
}

.LabelLinkSelected {
    background-color: var(--background-color);
}
