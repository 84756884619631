.LightTheme {
  --primary-color: #650d61;
  --secondary-color: #c389c1;

  --black: #211522;
  --tertiary-color: #eea3eb;
  --background-color: #f8ddf7;

  --positive-color: #05820b;
  --negative-color: #970000;
  --negative-muted-color: #f27979;

  --default-text-color: var(--black);
  --default-header-text-color: var(--primary-color);

  --padding-tiny: 0.25em;
  --padding-small: 0.5em;
  --padding-medium: 1em;
  --padding-large: 2em;

  --margin-tiny: 0.25em;
  --margin-small: 0.5em;
  --margin-medium: 1em;
  --margin-large: 2em;

  --border-radius: 5px;
  --border-width: 2px;

  --form-font-size: 1em;

  --wavy-primary-color: #eea3eb11;
  --curly-primary-color: #eea3eb22;
  --coily-primary-color: #eea3eb33;
}

a {
  color: var(--primary-color);
}