.Container {
    width: 100%;
    display: flex;
    margin-bottom: var(--margin-medium);
}

.Tab {
    border-bottom: 5px solid var(--secondary-color);
    padding-bottom:var(--padding-medium);
    padding-left:var(--padding-small);
    padding-right:var(--padding-small);
    flex-grow: 1;
    text-align: center;
}

.Tab:not(:last-child) {
    margin-right: var(--margin-small);
}

.Inactive {
    /*color: var(--secondary-color);*/
    border-bottom: 5px solid;
    cursor: pointer;
}

.Active {
    color: var(--primary-color);
    border-bottom: 5px solid var(--secondary-color);
}

