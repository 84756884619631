.Container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
}

.Video {
  position: absolute;
  top: 5vh;
  left: 5vw;
  width: 90vw;
  height: 90vh;
  object-fit: contain;
}

.Instructions {
  position: absolute;
  width: 100%;
  background-color: var(--background-color);
  text-align: center;
  top: 0vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5vh;
}

.Message {
  flex-grow: 1;
  text-align: center;
  padding: var(--padding-small);
}

.Close {
  /*width: 20%;*/
  padding: var(--padding-small);
  cursor: pointer;
}

.Actions {
  /*min-height: 5vh;*/
  position: absolute;
  width: 100%;
  background-color: var(--background-color);
  text-align: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
